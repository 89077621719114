.carousel-container {
  width: 86.5%;
  margin: auto;
  position: relative;
  float: right;
}

.slick-track {
  width: 6876px;
  opacity: 1;
  transform: translate3d(-946px, 0px, 0px);

}

.carousel-item {
  position: relative;
  padding: 0 10px;
  /* Add padding for spacing */
  box-sizing: border-box;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

.carousel-caption {
  position: absolute;
  bottom: -162px;

  background: #f8f7f3;
  padding: 20px;
  border-radius: 5px;
  text-align: left;

}

.carousel-caption .category-date {
  font-size: 14px;
  color: #666;
}

.carousel-caption h2 {
  font-size: 24px;
  margin: 10px 0;
  color: #333;
}

.carousel-caption p {
  font-size: 16px;
  color: #666;
}

.carousel-caption .read-more {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  /* background: #ffcc00; */
  color: #000;
  text-decoration: none;
  /* border-radius: 5px; */

}

.slick-next {
  right: 58px !important;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-slide {
  height: 700px;
}

.slick-prev {
  /* left: -25px; */
  right: 120px !important;
  left: unset !important;
}

.slick-prev,
.slick-next {
  bottom: 106px !important;
  top: 87% !important;
}




.slick-prev:before,
.slick-next:before {
  font-size: 48px !important;
}



@media screen and (max-width: 450px) {

  .slick-prev,
  .slick-next {
    bottom: 106px !important;
    top: 65% !important;
  }

  .carousel-item {
    padding: 0 5px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
  }

  .slick-prev {
    /* left: -25px; */
    right: 95px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .slick-slide {
    height: 438px;
  }
}


@media screen and (min-width: 375px) and (max-width: 425px) {
  .banner-blog-card .blog-detail-box {
    min-height: 230px !important;
  }

  .carousel-container {
    height: 480px;
  }

  .slick-prev,
  .slick-next {
    bottom: 106px !important;
    top: 420px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {

  .slick-prev,
  .slick-next {
    bottom: 106px !important;
    top: 400px !important;

  }

  .carousel-container {
    height: 430px;
  }
}